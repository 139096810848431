<template>
<div/>
</template>
<script>
import queryString from 'query-string'
import moment from 'moment';

export default {
  name: 'AppUtil',

  methods: {
    datefmt (v,fmt) {
      var fm = fmt ? fmt : 'YYYY-MM-DD HH:mm:ss'
      moment.locale('ja')
      return moment(v).format(fm)
    },
    href (v) {
      this.$router.push(this.resolvePath(v), () => {}, () => {})
    },
    resolvePath (v) {
      const r = this.$router.resolve(v).route
      const q = queryString.stringify(v.query)
      if (q.length <= 0) return r.path
      return r.path + '?' + q
    },
  },
}
</script>