<template>
<div/>
</template>
<script>
import moment from 'moment'

export default {
  name: 'AppCommon',

  methods: {
    ApiAuthHeaders: function () {
      var token = 'shogi-kifu-apikey' // this.$store.getters['userState/apiToken']
      return { Authorization: 'Bearer ' + token }
    },
    generate_candidates (dates, hour, cnt) {
      var vv = []
      var fc = (_, i) => {
        //var d = 7 + parseInt(i/2)
        //var h = (i % 2) ? '10:00 ～ 11:00' : '14:00 ～ 15:00'

        moment.locale('ja')
        var tef = moment(dates[1]).add({'days': 1})
        var ts = moment(dates[0], 'YYYY-MM-DD')
        ts.add({'days': parseInt(i/2)})
        ts.set('hour', (i % 2 == 0) ? 10 : 14)
        var te = moment(ts)
        te.add({'hours': hour})
        var v = {
          icon: 'mdi-calendar-clock',
          avatar: 'https://cdn.vuetifyjs.com/images/lists/'+i+'.jpg',
          title: ts.format('YYYY/M/D(ddd)  HH:mm') + ' ～ ' + te.format('HH:mm'),
          date: {
            start: ts.format('YYYY-MM-DD HH:mm:00'),
            end: te.format('YYYY-MM-DD HH:mm:00'),
          },
          subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        }
        if (tef.isBefore(te)) return
        vv.push(v)
      }
      [ ...Array(cnt) ].map(fc)
      return vv
    },
  },

  data: () => ({
    appConfig: {
      maxCandidates: 5,
    },
    appData: {
      members: [
        '山田太郎',
        '鈴木花子',
        '佐藤次郎',
        '山本さくら',
      ],
    },
  }),
}
</script>